import React from "react"
import Try from "../components/try/try"
import HeaderSmall from "../components/header-small/header-small"
import SEO from "../components/seo"

const TFJSTestPage = () => (
  <>
    <SEO title="Search Tensorflow JS source code" />
    <Try isTestPage={true} projectName="tfjs"/>
    <div style={{ textAlign: "center", marginTop: 40 }}>
      <p>
        Powered by <a href="https://codecue.com">CodeCue.com</a>
      </p>
    </div>
  </>
)

export default TFJSTestPage
